import { HeroModule } from '@components/HeroModule'
import Layout from '@components/Layout'
import { PageModules } from '@components/PageModules'
import React from 'react'

export const PageApolloCPAContainer = ({ heroMedia, modules }) => {
  return (
    <>
      <HeroModule heroMedia={heroMedia} />
      <PageModules modules={modules} />
    </>
  )
}

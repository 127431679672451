import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from '@components/Layout'
import { ApolloCPAContainer } from '@containers/ApolloCPAContainer'
import { parseQueryString } from '@utils'
import Redirect from '@components/Redirect'

/**
 *
 * Testing purposes
 * Preview links
 *
 * http://localhost:8000/preview/?t=nightcap&id=1HYVKAhesedB3V11Wd69gM&contentType=page
 *
 */

const DEFAULT_HOME_PREVIEW_ID = 'Ie2GXTaN0PCfAQ7Si0rTB'

const Preview = ({ pageContext, location }) => {
  const [pageId, setPageId] = useState(DEFAULT_HOME_PREVIEW_ID)
  const [contentTypeId, setContentTypeId] = useState('page')
  const [loading, setLoading] = useState(true)
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const accessToken = parseQueryString('t')
      if (accessToken === process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN) {
        setAuth(true)
        setLoading(false)
      } else {
        setAuth(false)
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && auth) {
      const id = parseQueryString('id')
      const contentTypeId = parseQueryString('contentType') || 'page'
      setContentTypeId(contentTypeId)
      setPageId(id)
    }
  }, [auth, location])

  if (loading)
    return (
      <Layout {...pageContext} location={location}>
        <div />
      </Layout>
    )
  if (!auth) return <Redirect to="/" />

  return (
    <Layout {...pageContext} location={location} preview>
      <ApolloCPAContainer id={pageId} contentTypeId={contentTypeId} />
    </Layout>
  )
}

Preview.propTypes = {
  pageContext: PropTypes.object,
}

export default Preview

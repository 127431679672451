import { useEffect } from 'react'
import { navigate } from 'gatsby'

const Redirect = ({ to }) => {
  useEffect(() => {
    navigate(to || '/')
  }, [to])

  return null
}

export default Redirect

import React, { useEffect, useState } from 'react'
import { PageApolloCPAContainer } from './PageApolloCPAContainer'
import usePreviewQuery, { GET_ENTRY_BY_ID } from '@hooks/usePreviewQuery'
import { useLocale } from '@hooks'
import { recursivelyGetLinkedEntriesAndSpreadFields } from '@utils'

const Preview = ({ id, contentTypeId, ...rest }) => {
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY_BY_ID,
    id: id,
    skip: !id,
    locale,
  })

  const [content, setContent] = useState([])

  useEffect(() => {
    if (!loading && !error && data) {
      const {
        entry: { includes, items },
      } = data

      const Entry = includes?.Entry || []
      const Asset = includes?.Asset || []
      const { sys, fields } = items?.[0]
      let mappedData = {
        ...fields,
        id: sys?.id,
        type: sys?.contentType?.sys?.id,
      }

      mappedData = recursivelyGetLinkedEntriesAndSpreadFields(
        mappedData,
        Entry,
        Asset
      )

      setContent(mappedData)
    }
  }, [loading, data, error])

  if (!loading && data && !error) {
    switch (contentTypeId) {
      case 'page':
        return <PageApolloCPAContainer {...content} {...rest} />
      default:
        return null
    }
  } else return <div />
}

export default Preview

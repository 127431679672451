import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '@components/Image'
import { Carousel } from '@components/Carousel'
import { StyledContainer } from './styles'

const Wrapper = ({ children, ...rest }) => {
  return (
    <StyledContainer
      display="flex"
      flexDirection="column"
      maxWidth={['90%', '90%', null, '1664px']}
      mx={['auto', 'auto', '96px', '128px']}
      my={4}
      {...rest}
    >
      {children}
    </StyledContainer>
  )
}

const HeroModule = ({ __typename, ...rest }) => {
  switch (__typename) {
    case 'ContentfulModuleCarousel':
      return (
        <Wrapper mt="64px">
          <Carousel {...rest} />
        </Wrapper>
      )
    case 'ContentfulModuleImage':
      return <Image {...rest} width="100vw" height="90vh" />
    default:
      return null
  }
}

HeroModule.propTypes = {
  heroMedia: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    fullWidth: PropTypes.object,
    constrained: PropTypes.object,
  }),
}

export default HeroModule
